import React from 'react'

export interface SmartFallbackProps extends React.PropsWithChildren<any> {
  onLoad?: (() => void | Promise<void>) | undefined
}

/**
 * Component that, if used as a `fallback` React Suspense component, will invoke
 * its `onLoad` callback when it is unmounted.
 */
const SmartFallback = React.memo((props: SmartFallbackProps) => {
  React.useEffect(() => {
    return () => {
      if (typeof props.onLoad === 'function') void props.onLoad()
    }
  }, [props.onLoad])

  return (props.children ?? null) as React.JSX.Element
})

export interface Props extends Partial<React.ComponentProps<typeof React.Suspense>> {
  onLoad?: SmartFallbackProps['onLoad']
}

/**
 * React Suspense with an `onLoad` callback that can be used to determine when
 * the await-ed component tree is ready.
 */
const SmartSuspense = React.memo(({ children, fallback, onLoad }: Props) => {
  const Fallback = React.useMemo(() => (
    <SmartFallback onLoad={onLoad}>
      {fallback}
    </SmartFallback>
  ), [fallback, onLoad])

  return (
    <React.Suspense fallback={Fallback}>
      {children}
    </React.Suspense>
  )
})

export default SmartSuspense