import arrayShuffle from 'array-shuffle'
import ms from 'ms'
import { transparentize } from 'polished'
import * as R from 'ramda'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import {
  COLOR,
  GRADIENT_HOLD_TIME_BASE,
  GRADIENT_INITIAL_PRESET,
  GRADIENT_LOG_SCALE_FACTOR,
  GRADIENT_ROTATION_INTERVAL,
  GRADIENT_TRANSITION_TIME_BASE
} from 'etc/constants'
import { safeMargins } from 'etc/global-styles.css'
import { gradients, type GradientName } from 'etc/gradients'
import { useIsTabActive } from 'hooks/use-is-tab-active'
import logger from 'lib/log'
import { createTimer } from 'lib/timer'
import { random } from 'lib/utils'

/**
 * @private
 *
 * Timing function to use when transitioning between gradients.
 */
const transitionFunction = 'ease'

/**
 * @private
 *
 * How many gradient presets are in rotation.
 */
const numPresets = R.keys(gradients).length

/**
 * @private
 *
 * Whether we are in dev mode or not.
 */
const isDev = import.meta.env.DEV

/**
 * @private
 *
 * Scoped logger.
 */
const log = logger.create({ heading: '🌅 •' })

/**
 * @private
 *
 * Amount of time to pause on a gradient before transitioning.
 */
const gradientHoldTime = (turnCount = 0) => {
  void turnCount
  return GRADIENT_HOLD_TIME_BASE
}

/**
 * @private
 *
 * Amount of time to spend transitioning between gradients.
 *
 * - Turn 0:  192 seconds
 * - Turn 60: 256 seconds
 */
const gradientTransitionTime = (turnCount = 0) => Math.round(
  GRADIENT_LOG_SCALE_FACTOR * Math.log10(turnCount + 1) + GRADIENT_TRANSITION_TIME_BASE
)

/**
 * @private
 *
 * Returns a random-ish angle to use for gradients.
 */
const getAngle = () => random.arrayElement(
  R.chain(num => R.range(num - 16, num + 16), [45, 135, 225, 315])
)

/**
 * @private
 *
 * Returns a new list of shuffled gradient presets. An optional preset may be
 * provided to omit from the list.
 */
const getShuffledPresets = ({ withoutPreset }: {withoutPreset?: GradientName} = {}) => {
  return arrayShuffle(R.without([withoutPreset], R.keys(gradients)))
}

interface ParsedLinearGradient {
  original: string
  prologue: string
  angle: number
  epilogue: string
}

const parseLinearGradient = (value: string) => {
  const pattern = /^(linear-gradient\(\s*)(\d+)(deg.*)$/g
  const matches = pattern.exec(value)
  if (!matches) return
  const [original, prologue, angle, epilogue] = matches
  return { original, prologue, angle: Number.parseInt(angle), epilogue } as ParsedLinearGradient
}

const formatLinearGradient = (value: ParsedLinearGradient) => {
  const { prologue, angle, epilogue } = value
  return `${prologue}${String(angle)}${epilogue}`
}

/**
 * @private
 *
 * Renders a small, partially transparent badge-like component used to identify
 * the gradient (as used, either "A" or "B").
 */
function GradientIdentifier({ children }: React.PropsWithChildren) {
  return (
    <span
      className="inline-block rounded font-bold text-center text-black px-1"
      style={{ backgroundColor: transparentize(0.5, COLOR.PRIMARY) }}
    >{children}</span>
  )
}

/**
 * @private
 *
 * Used in conjunction with GradientIdentifier to build labels for the active
 * gradient.
 */
function GradientLabel(props: React.HTMLProps<HTMLLabelElement>) {
  return (
    <label
      className={twMerge('flex', 'items-center', 'justify-between', 'gap-2', props.className)}
      style={{
        color: transparentize(0.5, COLOR.PRIMARY),
        ...props.style
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...R.omit(['className', 'style'], props)}
    >
      {props.children}
    </label>
  )
}

/**
 * @private
 *
 * Compute the initial preset order by creating an array where the first item is
 * the preferred first preset and the remaining presets are shuffled.
 */
const initialPresetOrder: Array<GradientName> = [
  GRADIENT_INITIAL_PRESET,
  ...getShuffledPresets({ withoutPreset: GRADIENT_INITIAL_PRESET })
]

/**
 * Facilitates cycling through a list of gradient presets, applying each to
 * alternating elements and fading between them using opacity. This technique
 * lets us transition between properties that CSS doesn't support transitions
 * on, such as linear-gradient backgrounds.
 */
export default React.memo(() => {
  /**
   * [State] Counter that is incremented each time we advance presets,
   * pause-able timer that tracks time remaining in each turn.
   */
  const [turnCount, incrementTurnCount] = React.useReducer(R.add(1), 0)

  /**
   * [State] Timer instance used to track the amount of time remaining in the
   * current turn. This is paused and resumed as the tab or window loses and
   * regains focus.
   */
  const [nextTurnTimer] = React.useState(() => createTimer({
    duration: 0,
    start: false,
    onEnd: incrementTurnCount,
    onCancel: timeRemaining => {
      log.info('[Timer] Canceled with time remaining:', timeRemaining)
    },
    onPause: timeRemaining => {
      log.info('[Timer] Paused with time remaining:', timeRemaining)
    },
    onResume: timeRemaining => {
      log.info('[Timer] Resumed with time remaining:', timeRemaining)
    }
  }))

  /**
   * [State] With the exception of the preset used on page load, presets are
   * visited in a random order. This randomness is facilitated using periodic
   * shuffling with some added logic to prevent the same preset from being used
   * twice consecutively.
   */
  const [shuffledPresets, setShuffledPresets] = React.useState(initialPresetOrder)

  /**
   * [State] Turns can be thought of as having a "transition" phase and a "hold"
   * phase. This variable tracks whether we are in the former phase of a turn,
   * during which certain behaviors are modified.
   */
  const [isTransitioning, setIsTransitioning] = React.useState(false)

  /**
   * [State] Tracks the name and styles for the preset currently in slot A.
   */
  const [gradientAName, setGradientAName] = React.useState<GradientName>()
  const [gradientAStyles, setGradientAStyles] = React.useState<React.CSSProperties>({})

  /**
   * [State] Tracks the name and styles for the preset currently in slot B.
   */
  const [gradientBName, setGradientBName] = React.useState<GradientName>()
  const [gradientBStyles, setGradientBStyles] = React.useState<React.CSSProperties>({})

  /**
   * [State] Tracks whether the tab is visible or not.
   */
  const isTabActive = useIsTabActive()

  /**
   * [Memo] Common CSS `transition` rule applied to gradients and labels. We
   * use a logarithmic scale to slowly increase turn times, so this rule must
   * be re-computed each turn.
   */
  const transition = React.useMemo(() => {
    const turnDuration = Math.round(gradientTransitionTime(turnCount))
    return `opacity ${turnDuration}ms ${transitionFunction}`
  }, [turnCount])

  /**
   * [Effect] Update gradients whenever `turnCount` is incremented.
   */
  React.useEffect(() => {
    // ----- Configure Transitioning Flag --------------------------------------

    let transitionEndTimer: NodeJS.Timeout

    // There is no transition on the first turn, so we can skip this logic.
    if (turnCount > 0) {
      setIsTransitioning(true)

      transitionEndTimer = setTimeout(() => {
        log.info(`Turn ${turnCount} • Transition complete.`)
        setIsTransitioning(false)
      }, gradientTransitionTime(turnCount))
    }

    // ----- Compute New Styles ------------------------------------------------

    const gradientName = shuffledPresets[turnCount % shuffledPresets.length]

    const presetFn = gradients[gradientName]
    if (!presetFn) throw new Error(`Unknown preset: ${gradientName}`)

    const angle = turnCount === 0 ? 145 : getAngle()
    const { style } = presetFn(angle)
    const turnDuration = gradientHoldTime(turnCount) + gradientTransitionTime(turnCount)

    // ----- Update Gradient Styles --------------------------------------------

    if (turnCount % 2 === 0) {
      log.info(`Turn ${turnCount} • A • ${gradientName}, ${angle}deg, ${ms(turnDuration)}`)

      setGradientAName(gradientName)
      setGradientAStyles(prevStyle => ({
        ...prevStyle,
        ...style,
        opacity: style.opacity ?? 1
      }))

      setGradientBStyles(prevStyle => ({ ...prevStyle, opacity: 0, transition }))
    } else {
      log.info(`Turn ${turnCount} • B • ${gradientName}, ${angle}deg, ${ms(turnDuration)}`)

      setGradientBName(gradientName)
      setGradientBStyles(prevStyle => ({
        ...prevStyle,
        ...style,
        opacity: style.opacity ?? 1
      }))

      setGradientAStyles(prevStyle => ({ ...prevStyle, opacity: 0, transition }))
    }

    // ----- Shuffle Presets ---------------------------------------------------

    // Ensures that presets are shuffled every `numPresets` turns; every time
    // the set of presets has been cycled-through.
    if (turnCount === 0) {
      // Do nothing on the first turn.
    } else if (turnCount % numPresets === numPresets - 1) {
      log.info(`Turn ${turnCount} • Shuffling Presets`)
      // By removing the current preset from the next set of shuffled presets,
      // we ensure the same preset can't be used twice consecutively. The absent
      // preset will then be re-added to the mix the next time we shuffle them.
      setShuffledPresets(getShuffledPresets({ withoutPreset: gradientName }))
    }

    // ----- Set & Start Turn Timer --------------------------------------------

    nextTurnTimer.reset({ duration: turnDuration, start: true })

    return () => clearTimeout(transitionEndTimer)
  }, [
    turnCount
  ])

  /**
   * [Effect] Installs the event handlers for skipping turns. Skipping a turn
   * entails skipping the remaining hold time in the turn and starting the next
   * transition immediately.
   */
  React.useEffect(() => {
    let timeoutHandle: NodeJS.Timeout

    const onMouseUp = () => {
      clearTimeout(timeoutHandle)
    }

    const onMouseDown = () => {
      timeoutHandle = setTimeout(() => {
        // To ensure transitions remain as smooth as possible, we don't want the
        // user to be able to skip a turn during a transition phase.
        if (isTransitioning) {
          log.info('This feature is disabled during transitions.')
          return
        }

        log.info('Transitioning to next preset.')
        incrementTurnCount()
      }, 800)
    }

    globalThis.addEventListener('mousedown', onMouseDown)
    globalThis.addEventListener('touchstart', onMouseDown)

    globalThis.addEventListener('mouseup', onMouseUp)
    globalThis.addEventListener('touchend', onMouseUp)

    return () => {
      globalThis.removeEventListener('mousedown', onMouseDown)
      globalThis.removeEventListener('touchstart', onMouseDown)

      globalThis.removeEventListener('mouseup', onMouseUp)
      globalThis.removeEventListener('touchend', onMouseUp)
    }
  }, [
    isTransitioning
  ])

  /**
   * [Effect] Responsible for pausing and resuming activities when the tab
   * becomes inactive:
   *
   * - Creates or cancels an interval which updates the angle of the linear
   *   gradient in both containers each `GRADIENT_ROTATION_INTERVAL`.
   * - Pauses and resumes the next turn timer, which increments the turn count
   *   when it expires.
   */
  React.useEffect(() => {
    if (!isTabActive) {
      nextTurnTimer.pause()
      return
    }

    nextTurnTimer.start()

    const rotateGradients = (prevStyles: React.CSSProperties) => {
      const { background } = prevStyles
      if (typeof background !== 'string') return prevStyles
      const trimmedBackground = background.replaceAll(/\s{2,}/g, ' ')
      const parsed = parseLinearGradient(trimmedBackground)
      if (!parsed) return prevStyles
      parsed.angle = parsed.angle + 1
      const formatted = formatLinearGradient(parsed)
      return { ...prevStyles, background: formatted }
    }

    const intervalHandle = setInterval(() => {
      setGradientAStyles(rotateGradients)
      setGradientBStyles(rotateGradients)
    }, GRADIENT_ROTATION_INTERVAL)

    return () => clearInterval(intervalHandle)
  }, [isTabActive])

  return (
    <div
      className="fixed inset-0 z-0"
      aria-hidden="true"
      data-testid="animated-gradient"
    >
      {/* Gradient A */}
      <div
        className="absolute inset-0 opacity-0"
        style={{ ...gradientAStyles, animationPlayState: isTabActive ? 'running' : 'paused' }}
      />

      {/* Gradient B */}
      <div
        className="absolute inset-0 opacity-0"
        style={{ ...gradientBStyles, animationPlayState: isTabActive ? 'running' : 'paused' }}
      />

      {/* DevTools */}
      {isDev && (
        <aside
          className={twMerge(
            'flex justify-between items-center p-3 text-sm mix-blend-screen',
            safeMargins
          )}
        >
          <GradientLabel style={{ opacity: turnCount % 2 === 0 ? 1 : 0 }}>
            <GradientIdentifier>A</GradientIdentifier>
            {gradientAName}
          </GradientLabel>
          <GradientLabel style={{ opacity: turnCount % 2 === 1 ? 1 : 0 }}>
            {gradientBName}
            <GradientIdentifier>B</GradientIdentifier>
          </GradientLabel>
        </aside>
      )}
    </div>
  )
})